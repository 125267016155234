.emojione {
  font-size: inherit;
  vertical-align: middle;
  object-fit: contain;
  margin: -.2ex .15em .2ex;
  width: 16px;
  height: 16px;

  img {
    width: auto;
  }
}

.emoji-picker-dropdown__menu {
  background: $simple-background-color;
  position: absolute;
  box-shadow: 4px 4px 6px rgba($base-shadow-color, 0.4);
  border-radius: 4px;
  margin-top: 5px;
  z-index: 2;

  .emoji-mart-scroll {
    transition: opacity 200ms ease;
  }

  &.selecting .emoji-mart-scroll {
    opacity: 0.5;
  }
}

.emoji-picker-dropdown__modifiers {
  position: absolute;
  top: 60px;
  right: 11px;
  cursor: pointer;
}

.emoji-picker-dropdown__modifiers__menu {
  position: absolute;
  z-index: 4;
  top: -4px;
  left: -8px;
  background: $simple-background-color;
  border-radius: 4px;
  box-shadow: 1px 2px 6px rgba($base-shadow-color, 0.2);
  overflow: hidden;

  button {
    display: block;
    cursor: pointer;
    border: 0;
    padding: 4px 8px;
    background: transparent;

    &:hover,
    &:focus,
    &:active {
      background: rgba($ui-secondary-color, 0.4);
    }
  }

  .emoji-mart-emoji {
    height: 22px;
  }
}

.emoji-mart-emoji {
  span {
    background-repeat: no-repeat;
  }
}

.emoji-button {
  display: block;
  padding: 5px 5px 2px 2px;
  outline: 0;
  cursor: pointer;

  &:active,
  &:focus {
    outline: 0 !important;
  }

  img {
    filter: grayscale(100%);
    opacity: 0.8;
    display: block;
    margin: 0;
    width: 22px;
    height: 22px;
  }

  &:hover,
  &:active,
  &:focus {
    img {
      opacity: 1;
      filter: none;
    }
  }
}
