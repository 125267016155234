.directory {
  &__list {
    width: 100%;
    margin: 10px 0;
    transition: opacity 100ms ease-in;

    &.loading {
      opacity: 0.7;
    }

    @media screen and (max-width: $no-gap-breakpoint) {
      margin: 0;
    }
  }

  &__card {
    box-sizing: border-box;
    margin-bottom: 10px;

    &__img {
      height: 125px;
      position: relative;
      background: darken($ui-base-color, 12%);
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
        object-fit: cover;
      }
    }

    &__bar {
      display: flex;
      align-items: center;
      background: lighten($ui-base-color, 4%);
      padding: 10px;

      &__name {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        text-decoration: none;
        overflow: hidden;
      }

      &__relationship {
        width: 23px;
        min-height: 1px;
        flex: 0 0 auto;
      }

      .avatar {
        flex: 0 0 auto;
        width: 48px;
        height: 48px;
        padding-top: 2px;

        img {
          width: 100%;
          height: 100%;
          display: block;
          margin: 0;
          border-radius: 4px;
          background: darken($ui-base-color, 8%);
          object-fit: cover;
        }
      }

      .display-name {
        margin-left: 15px;
        text-align: left;

        strong {
          font-size: 15px;
          color: $primary-text-color;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        span {
          display: block;
          font-size: 14px;
          color: $darker-text-color;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &__extra {
      background: $ui-base-color;
      display: flex;
      align-items: center;
      justify-content: center;

      .accounts-table__count {
        width: 33.33%;
        flex: 0 0 auto;
        padding: 15px 0;
      }

      .account__header__content {
        box-sizing: border-box;
        padding: 15px 10px;
        border-bottom: 1px solid lighten($ui-base-color, 8%);
        width: 100%;
        min-height: 18px + 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        p {
          display: none;

          &:first-child {
            display: inline;
          }
        }

        br {
          display: none;
        }
      }
    }
  }
}

.filter-form {
  background: $ui-base-color;

  &__column {
    padding: 10px 15px;
  }

  .radio-button {
    display: block;
  }
}

.radio-button {
  font-size: 14px;
  position: relative;
  display: inline-block;
  padding: 6px 0;
  line-height: 18px;
  cursor: default;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  input[type=radio],
  input[type=checkbox] {
    display: none;
  }

  &__input {
    display: inline-block;
    position: relative;
    border: 1px solid $ui-primary-color;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    flex: 0 0 auto;
    margin-right: 10px;
    top: -1px;
    border-radius: 50%;
    vertical-align: middle;

    &.checked {
      border-color: lighten($ui-highlight-color, 8%);
      background: lighten($ui-highlight-color, 8%);
    }
  }
}
